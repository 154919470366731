// Node modules
import React from 'react'
import { GatsbyImage } from "gatsby-plugin-image";

const PageHeder = (props) => {

    return (
        <>
            <section className="w-full mt-20 h-80">
                <div className="grid h-full relative bg-template-black100" >
                    <GatsbyImage image={props.featureimage.childImageSharp.gatsbyImageData} alt={props.featureimagealt}
                        className={props.opacity ? "row-start-1 col-start-1 opacity-40" : "row-start-1 col-start-1 opacity-100"}
                        layout="fullWidth"
                        aspectratio={3 / 1}
                        style={{ gridArea: "1 / 1" }}
                        data-pin-nopin
                    />
                </div>
            </section>
        </>
    )
}

export default PageHeder